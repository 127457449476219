<template>
  <div>
    <div class="d-flex flex-column">
      <img
        v-if="src"
        :src="src"
        style="max-height: 50vh; max-width: 100%"
        class="mb-2"
      />
      <ButtonSelectFile
        v-if="isGather"
        @triggerUpload="showModal"
        :icon="srcCheck ? 'fa-pencil-alt' : 'fa-palette'"
        :hasError="false"
      >
        {{ srcCheck ? 'Edit' : 'Start' }} drawing
      </ButtonSelectFile>
      <div v-else class="alert alert-warning mb-0">
        <i class="fas fa-exclamation-triangle"></i>
        <span class="ms-2">Drawing is only available in Gather.</span>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonSelectFile from '../../components/ButtonSelectFile.vue';

export default {
  props: {
    inputValues: {
      required: true,
      type: Array,
    },
    inputValue: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    inputIndex: null,
    file: null,
    isGather: false,
  }),
  components: {
    ButtonSelectFile,
  },
  watch: {
    'inputValue.value'() {
      this.setFile();
    },
  },
  computed: {
    srcCheck() {
      return !!this.file?.src;
    },
    src() {
      if (this.inputValue?.value instanceof Blob)
        return URL.createObjectURL(this.inputValue.value);
      if (typeof this.inputValue?.value == 'string')
        return `/api/images/value/${this.inputValue.project_id}/${this.inputValue.value}`;
      return false;
    },
  },
  mounted() {
    this.isGather = import.meta.env.VITE_APP_URL.toLowerCase().includes(
      'gather'
    );
    const inputValueIndex = this.inputValues.findIndex(
      (inputValue) =>
        JSON.stringify(inputValue) === JSON.stringify(this.inputValue)
    );
    this.inputIndex = inputValueIndex;
    this.setFile();
  },
  methods: {
    setFile() {
      const value = this.inputValue.value;
      if (value && value instanceof Blob) {
        this.file = {
          blob: value,
          src: URL.createObjectURL(value),
        };
      } else if (this.inputValue.value) {
        this.file = { blob: null, src: value };
      }
    },
    showModal() {
      this.$emit('clickStartDrawing', {
        useDrawing: true,
        inputIndex: this.inputIndex,
      });
    },
  },
};
</script>
