<template>
  <div class="w-100">
    <ButtonSelectFile
      @triggerUpload="triggerFileUpload"
      :hasError="hasError"
      :isUploading="loading"
      :icon="captureOnly ? 'fa-camera-alt' : 'fa-upload'"
    >
      {{ captureOnly ? 'Capture' : `Select ${hasMultiple ? 'files' : 'file'}` }}
    </ButtonSelectFile>

    <input
      v-if="!captureOnly"
      type="file"
      :multiple="allowMultiple"
      accept="image/*"
      class="form-control-file d-none"
      ref="imageInput"
      @change="handleFileUpload"
    />
    <input
      v-else
      type="file"
      accept="image/*"
      class="form-control-file d-none"
      ref="imageInput"
      capture="enviroment"
      @change="handleFileUpload"
    />
  </div>
</template>

<script>
import ButtonSelectFile from '../../components/ButtonSelectFile.vue';
import InputText from '../../components/InputText.vue';

export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    captureOnly: {
      type: Boolean,
      default: false,
    },
    hasMultiple: {
      type: Boolean,
      default: false,
    },
    allowMultiple: {
      type: Boolean,
      default: false,
    },
    allowRenaming: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    triggerFileUpload() {
      if (this.loading) return;
      this.$refs.imageInput.click();
    },
    handleFileUpload(e) {
      const files = e.target.files;
      if (!files.length) {
        return;
      }
      this.loading = true;
      this.$emit('isLoading', true);
      try {
        const compressedFiles = [];
        for (let x = 0; x < files.length; x++) {
          if (!files[x].type.includes('image')) {
            this.$toastStore.error(
              `${files[x].name} file type is not supported for image upload`
            );
            this.loading = false;
            this.$emit('isLoading', false);
            return;
          }
          compressedFiles.push(this.compressFile(files[x]));
        }
        Promise.all(compressedFiles).then((blobs) => {
          const blobArr = blobs.map((data) => {
            if (!data?.blob) return data;
            return {
              src: URL.createObjectURL(data.blob),
              blob: data.blob,
              name: data.name,
            };
          });
          this.$emit('input', [...this.value, ...blobArr]);
          this.loading = false;
          this.$emit('isLoading', false);
        });
      } catch (e) {
        this.loading = false;
        this.$emit('isLoading', false);
        throw e;
      }
    },
    compressFile(file) {
      return new Promise((resolve) => {
        const maxWidth = 3072;
        const maxHeight = 3072;
        const mimeType = 'image/jpeg';
        const blobURL = URL.createObjectURL(file);
        const img = new Image();
        img.src = blobURL;
        img.onerror = (e) => {
          URL.revokeObjectURL(img.src);
          this.$toastStore.error(
            `${file.name} file type is not supported for image upload`
          );
          this.loading = false;
          this.$emit('isLoading', false);
          throw e;
        };
        img.onload = () => {
          const [newWidth, newHeight] = this.calculateSize(
            img,
            maxWidth,
            maxHeight
          );
          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          URL.revokeObjectURL(img.src);
          canvas.toBlob(
            (blob) => {
              let { name } = file;
              if (name && !name.endsWith('.jpg')) {
                const parts = name.split('.');
                if (parts.length > 1) {
                  parts.pop();
                }
                name = parts.join('.');
                name = name + '.jpg';
              }
              resolve({ name, blob });
            },
            mimeType,
            0.8
          );
        };
      });
    },
    calculateSize(img, maxWidth, maxHeight) {
      let width = img.width;
      let height = img.height;
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }
      return [width, height];
    },
  },
  components: { ButtonSelectFile, InputText },
};
</script>

<style scoped>
.btn-outline-primary {
  width: 5.8em;
  height: 5.8em;
}

.icon {
  display: inline-block;
  background-size: cover;
  position: relative;
  left: -0.9em;
  top: -0.9em;
}

.icon-datanest {
  width: 5em;
  height: 5em;
  mask: url('/images/datanest-logo.svg');
}

.icon:hover,
.icon:focus {
  background: #fff !important;
}
</style>
