import axios, { AxiosRequestConfig, AxiosResponse, type Axios } from 'axios';
import { DATANEST_URL } from '../env';

let client: Axios | null = null;
let clientPublic: Axios | null = null;
let auth: any | null = null;

type Interceptors = {
  doBeforeRequestIsSent:
    | ((
        value: AxiosRequestConfig<any>
      ) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>)
    | undefined;
  handleRequestError: ((error: any) => any) | undefined;
  doAfterResponseIsReady:
    | ((
        value: AxiosResponse<any, any>
      ) => AxiosResponse<any, any> | Promise<AxiosResponse<any, any>>)
    | undefined;
  handleResponseError: ((error: any) => any) | undefined;
};

let _interceptors: Interceptors | undefined = undefined;

export function setInterceptors(interceptors: Interceptors) {
  _interceptors = interceptors;
}

export function waitForAuth() {
  if (auth && auth.token()) {
    return;
  }

  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (auth?.token()) {
        clearInterval(interval);
        resolve(null);
      }
    }, 100);
  });
}

export default function useApi(): Axios {
  if (!client) {
    const token = auth?.token();
    if (!token) {
      throw new Error('Authenticated API has not got a token!');
    }
    setToken(token);
  }

  // ! setToken would have set a new client
  return client!;
}

export function usePublicApi() {
  if (clientPublic) {
    return clientPublic;
  }
  return (clientPublic = axios.create({
    baseURL: getBaseUrl(),
    headers: {
      'Content-Type': 'application/json',
    },
  }));
}

export function setApiAuth(authObject: any) {
  auth = authObject;
}

export function setToken(token: string | null) {
  if (!token) {
    if (client) {
      // @ts-expect-error dynamic header
      client.defaults.headers.Authorization = null;
    }
  }

  const bearer = `Bearer ${token}`;
  if (client) {
    // @ts-expect-error dynamic header
    client.defaults.headers.Authorization = bearer;
  } else {
    client = axios.create({
      baseURL: getBaseUrl(),
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
    });

    if (_interceptors) {
      const {
        doBeforeRequestIsSent,
        handleRequestError,
        doAfterResponseIsReady,
        handleResponseError,
      } = _interceptors;
      client.interceptors.request.use(
        doBeforeRequestIsSent,
        handleRequestError
      );
      client.interceptors.response.use(
        doAfterResponseIsReady,
        handleResponseError
      );
    }
  }
}

// Get the base url with fallbacks for both Gather and Datanest
export function getBaseUrl() {
  // explicit hub api for future use.
  if (import.meta.env.VITE_HUB_API_URL) {
    return import.meta.env.VITE_HUB_API_URL;
  }
  if (!DATANEST_URL) {
    throw new Error('No DATANEST_URL found, missing env configuration?');
  }
  // If running in local container
  if (
    typeof window !== 'undefined' &&
    window.location.host.includes('nginx-datanest')
  ) {
    return '/api';
  }
  return `${DATANEST_URL}/api`;
}
