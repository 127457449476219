export function checkIsOptionDuplicate(options: string[], option: string) {
  return !!options.find((o) => o.toLowerCase() === option.toLowerCase());
}

export function getOptionIconSrc(option: string, field: any) {
  const fieldOption = field.options?.node_icons
    ? field.options.node_icons[option] || null
    : null;

  const iconId = fieldOption ? fieldOption.icon_id : null;
  if (!iconId) {
    return null;
  }

  const color = encodeURIComponent(fieldOption.color);
  return `/markers/${iconId}?fill=${color}&stroke=${color}&width=16&height=16`;
}
