<template>
  <div class="form-group">
    <label class="form-label">
      {{ field.label }}
      <sup v-if="field.is_required" class="text-danger">*</sup>
      <a
        v-if="value && !field.options.display_as_buttons"
        href="#"
        @click.prevent="value = null"
      >
        Clear selection
      </a>
    </label>

    <div
      v-if="field.options.display_as_buttons"
      class="d-block btn-group btn-group-toggle mb-2"
    >
      <button
        type="button"
        class="btn btn-outline-dark"
        :class="{
          'text-bg-dark': isActive,
        }"
        :disabled="isDisabled"
        @click="() => (value = 'yes')"
      >
        Yes
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        :class="{
          'text-bg-dark': notActive,
        }"
        :disabled="isDisabled"
        @click="() => (value = 'no')"
      >
        No
      </button>
    </div>
    <div v-else>
      <div class="form-check form-check-inline" @click="() => (value = 'yes')">
        <input
          class="form-check-input"
          type="radio"
          :id="yesRadioId"
          v-model="value"
          value="yes"
          :disabled="isDisabled"
        />
        <label class="form-check-label" :for="yesRadioId"> Yes </label>
      </div>
      <div class="form-check form-check-inline" @click="() => (value = 'no')">
        <input
          class="form-check-input"
          type="radio"
          :id="noRadioId"
          v-model="value"
          value="no"
          :disabled="isDisabled"
        />
        <label class="form-check-label" :for="noRadioId">No</label>
      </div>
    </div>
  </div>
</template>

<script>
import makeId from '../../local-id.mjs';

export default {
  props: {
    field: Object,
    inputValue: Object,
    defaultValue: {
      required: false,
      default: false,
    },
    isAutoAssignEnabled: Boolean,
    isAutoAssignActive: Boolean,
    isDisabled: Boolean,
  },
  data: () => ({
    isDefaultInputValue: false,
    noRadioId: makeId(),
    yesRadioId: makeId(),
  }),
  computed: {
    value: {
      get() {
        if (!this.inputValue) {
          return;
        }

        return this.inputValue.value;
      },
      set(updated) {
        const _isDefaultInputValue = this.isDefaultInputValue;
        if (_isDefaultInputValue) {
          this.isDefaultInputValue = false;
        }
        this.$root.$emit('updateInputValue', {
          inputValue: { ...this.inputValue, value: updated },
          field: this.inputValue.template_field_id,
          sectionIndex: this.inputValue.template_section_index,
          templateTabId: this.inputValue.template_tab_id,
          isDefaultInputValue: _isDefaultInputValue,
        });
      },
    },
    isActive() {
      return this.value && this.value.toLowerCase() == 'yes';
    },
    notActive() {
      return this.value && this.value.toLowerCase() == 'no';
    },
  },
  watch: {
    defaultValue: {
      handler(newValue) {
        if (
          !this.value ||
          (this.isAutoAssignEnabled && this.isAutoAssignActive)
        ) {
          this.isDefaultInputValue = true;
          this.value = newValue;
        }
      },
      immediate: true,
    },
    isAutoAssignActive(newValue) {
      if (newValue) {
        this.value = this.defaultValue;
      }
    },
  },
};
</script>
