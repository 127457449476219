import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useCollectionStore = defineStore('collection', () => {
    const isBusy = ref(false);

    return {
        isBusy: computed(() => isBusy.value),

        startBusy() {
            isBusy.value = true;
        },
        stopBusy() {
            isBusy.value = false;
        },
        clearBusy() {
            if (isBusy.value) {
                console.trace('Clearing busy state, while it was busy.');
            }
            isBusy.value = false;
        },
    };
});