export enum AutoAssignType {
  CONDITIONS = 1,
  LOOKUP = 2,
  EXPRESSION = 3,
  REFERENCE = 4,
}

export type Operator = '=' | '!=' | '<' | '<=' | '>' | '>=';

export type ConditionsDefault = {
  type?: AutoAssignType.CONDITIONS;
  field_id: number;
  operator: Operator;
  value: string;
  default: string;
};

export type LookupDefault = {
  type: AutoAssignType.LOOKUP;
  sourceFieldIds: number[];
  dictionary: Dictionary;
};

export type ExpressionDefault = {
  type: AutoAssignType.EXPRESSION;
  fieldId: number;
};

export type ReferenceDefault = {
  type: AutoAssignType.REFERENCE;
  referenceAppFieldId: number;
  referenceAppTitle: string;
  linkedFieldId: number | 'custom_title';
};

export type Default = ConditionsDefault | LookupDefault | ExpressionDefault | ReferenceDefault;

export type DefaultSelectOption = {
  value: string;
  displayValue: string;
};

export type Dictionary = string[][];

export function checkIsConditionsDefault(d: Default): d is ConditionsDefault {
  // Legacy defaults are condition defaults without the type.
  return !d.type || d.type === AutoAssignType.CONDITIONS;
}

export function checkIsReferenceDefault(d: Default): d is ReferenceDefault {
  return !d.type || d.type === AutoAssignType.REFERENCE;
}

export function checkIsLookupDefault(d: Default): d is LookupDefault {
  return d.type === AutoAssignType.LOOKUP;
}

export function checkIsExpressionDefault(d: Default): d is ExpressionDefault {
  return d.type === AutoAssignType.EXPRESSION;
}
