<script lang="ts" setup>
import { nextTick, onMounted, ref } from 'vue';
import InputText from '../../components/InputText.vue';
import { GatherField } from '../../gather';
import { InputValueValueFile, downloadInputValueFile } from '../../file-utils';

const props = defineProps<{
  value: InputValueValueFile[];
  field: GatherField;
}>();

const emit = defineEmits<{
  (event: 'input', value: { name: string; src?: string; blob?: Blob }[]): void;
  (
    event: 'setPreview',
    value: { name: string; src?: string; blob?: Blob }
  ): void;
  (event: 'updateFileName', value: { index: number; name: string }): void;
}>();

const displayMaxItems = ref(3),
  collapsed = ref(true),
  processed = ref(false);

onMounted(() => {
  nextTick(() => {
    const updateImages = props.value.map((file) => {
      if (file?.blob && file.blob instanceof Blob) {
        if (file.src) URL.revokeObjectURL(file.src);
        return { ...file, src: URL.createObjectURL(file.blob) };
      }
      return file;
    });
    emit('input', updateImages);
    nextTick(() => {
      processed.value = true;
    });
  });
});

function fileSize(number) {
  if (number < 1024) {
    return number + ' bytes';
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + ' KB';
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + ' MB';
  }
}

function deleteFile(fileIndex) {
  const updated = props.value.filter((item, index) => {
    return index != fileIndex;
  });
  emit('input', updated);
}

function setPreview(file) {
  emit('setPreview', file);
}

function updateFileName(file, index) {
  nextTick(() => {
    emit('updateFileName', { index, name: file.name });
  });
}

function displayFile(file, index) {
  if (!file) return false;
  return (
    index < displayMaxItems.value ||
    (index >= displayMaxItems.value && collapsed.value == false)
  );
}
</script>

<template>
  <div>
    <div v-if="processed" class="list-group mt-2">
      <template v-for="(file, index) of value">
        <div
          v-if="displayFile(file, index)"
          class="list-group-item d-flex"
          v-bind:key="String(file.src) + index"
        >
          <img
            :src="file.src"
            class="rounded d-block me-2"
            style="max-width: 6em !important"
            loading="lazy"
            decoding="async"
            @click.prevent="setPreview(file)"
          />
          <div
            class="d-flex justify-content-between align-items-center w-100 overflow-hidden"
          >
            <div class="me-2 overflow-hidden d-flex flex-grow-1">
              <InputText
                v-if="file && field.options?.allow_renaming"
                v-model="file.name"
                @change.native="updateFileName(file, index)"
                is-small
                :label="`File name ${
                  file.blob ? `(${fileSize(file.blob.size)})` : ''
                }`"
                placeholder="Enter file name"
                class="mb-0 flex-grow-1"
              />
              <template v-else>
                <span class="text-nowrap">
                  {{ file.name || 'unknown' }}
                </span>
                <small v-if="file.blob" class="d-block text-muted">
                  {{ fileSize(file.blob.size) }}
                </small>
              </template>
            </div>
            <div class="d-flex">
              <div v-if="(file.blob || file.src) && file.name">
                <span
                  class="clickable"
                  @click.stop.prevent="downloadInputValueFile(file)"
                >
                  <i class="fas fa-fw fa-download text-primary"></i>
                </span>
              </div>
              <h5
                class="fal fa-fw fa-times text-muted mb-0 clickable"
                @click.prevent="deleteFile(index)"
              />
            </div>
          </div>
        </div>
      </template>
      <div v-if="value.length == 0" class="alert alert-danger mb-0">
        No images have been uploaded
      </div>
    </div>
    <div
      v-if="value.length > displayMaxItems"
      class="btn w-100 btn-outline-primary mt-3"
      @click="collapsed = !collapsed"
    >
      <i :class="`fas fa-chevron-${collapsed ? 'down' : 'up'}`"></i>
    </div>
  </div>
</template>
