<template>
  <div class="document-field">
    <label id="file" :class="{ 'mb-3': isReadonly }">
      {{ field.label }}<sup v-if="field.is_required" class="text-danger">*</sup>
      <template v-if="!isReadonly">
        <br />
        <small class="text-muted">
          (pdf,doc,docx,ods,odt,txt,xls,xlsx,csv,zip)
        </small>
      </template>
    </label>

    <div class="mt-2">
      <div v-show="!inputValue.value && !isReadonly">
        <ButtonSelectFile
          @triggerUpload="fileDocument"
          :hasError="field.is_required && !inputValue.value"
        >
          Upload Document
        </ButtonSelectFile>
        <input
          type="file"
          ref="documentUpload"
          class="form-control-file d-none"
          :accept="supportedTypes"
          @change="fileChange"
        />
      </div>

      <a
        v-if="inputValue.value && fileIcon"
        :href="src"
        target="_blank"
        :download="inputValue.value2"
        :class="{
          'file-link': isUsedInPublicForm,
        }"
        class="mb-2 d-block"
      >
        <span class="fw-medium d-block mb-2">{{ inputValue.value2 }}</span>
        <i :class="`${fileIcon} text-primary`"></i>
      </a>
      <div
        v-if="isInputValueUnset && isReadonly && !defaultValue"
        class="alert alert-warning"
      >
        No document found.
      </div>

      <button
        v-if="inputValue.value && !isReadonly"
        type="button"
        class="btn btn-outline-danger w-100 mt-2"
        @click.prevent="clearFile"
      >
        Remove File
      </button>
    </div>
  </div>
</template>

<script>
import * as bm from '../../business-model';
import * as bl from '../../business-logic';

import ButtonSelectFile from '../../components/ButtonSelectFile.vue';

export default {
  props: {
    field: {
      required: true,
    },
    inputValue: {
      type: Object,
      required: true,
    },
    inputValues: {
      type: Array,
      required: true,
    },
  },
  inject: ['formContext'],
  data: () => ({
    loading: false,
    mimeType: null,
    file: null,
    iconMap: {
      pdf: 'fas fa-file-pdf fa-3x',
      doc: 'fas fa-file-word fa-3x',
      docx: 'fas fa-file-word fa-3x',
      csv: 'fas fa-file-csv fa-3x',
      ods: 'fas fa-file-spreadsheet fa-3x',
      odt: 'fas fa-file-alt fa-3x',
      txt: 'fas fa-file-alt fa-3x',
      xlx: 'fas fa-file-spreadsheet fa-3x',
      xlsx: 'fas fa-file-spreadsheet fa-3x',
      zip: 'fas fa-file-archive fa-3x',
    },
    supportedTypes: '.pdf,.doc,.docx,.ods,.odt,.txt,.xls,.xlsx,.csv',
  }),
  components: {
    ButtonSelectFile,
  },
  computed: {
    isUsedInPublicForm() {
      return this.formContext?.type === bm.common.FORM_CONTEXT_TYPE_PUBLIC_FORM;
    },
    isInputValueUnset() {
      return bl.input_value.checkIsInputValueUnset(this.inputValue);
    },
    defaultValue() {
      const { default: defaultValue } = this.field.options;
      return defaultValue;
    },
    src() {
      if (this.inputValue?.value instanceof File)
        return URL.createObjectURL(this.inputValue.value);
      if (typeof this.inputValue?.value == 'string')
        return `/api/images/value/${
          this.inputValue.project_id ?? this.formContext?.project?.project_id
        }/${this.inputValue.value}?input_value_id=${this.inputValue.id}`;
      return false;
    },
    fileIcon() {
      if (this.mimeType) {
        try {
          return this.iconMap[this.mimeType];
        } catch (e) {
          console.error(e);
          return 'fas fa-file-alt fa-3x';
        }
      }
      return 'fas fa-times fa-3x';
    },
    isReadonly() {
      return this.field.options.is_readonly;
    },
  },
  watch: {
    'inputValue.value'(updated) {
      this.setDocumentContent(updated);
    },
  },
  methods: {
    updateInputValue(value, value2) {
      this.$root.$emit('updateInputValue', {
        inputValue: { ...this.inputValue, value, value2 },
        field: this.inputValue.template_field_id,
        sectionIndex: this.inputValue.template_section_index,
        templateTabId: this.inputValue.template_tab_id,
      });
    },
    fileChange(e) {
      const file = e.target.files[0];
      this.mimeType = this.getMimeType(file.name);
      this.updateInputValue(file, file.name);
    },
    setDocumentContent(value) {
      if (!value) {
        this.mimeType = null;
        return;
      }
      if (value && value instanceof File) {
        this.mimeType = this.getMimeType(value.name);
      }
      if (value && typeof value == 'string') {
        this.mimeType = this.getMimeType(this.inputValue.value2);
      }
    },
    getMimeType(value) {
      return value.split('.').at(-1).toLowerCase();
    },
    fileDocument() {
      this.$refs.documentUpload.click();
    },
    clearFile() {
      this.updateInputValue(null, null);
    },
  },
  mounted() {
    if ((this.isInputValueUnset || this.isReadonly) && this.defaultValue) {
      const { fileName, assetName } = this.defaultValue;
      this.updateInputValue(assetName, fileName);
    }

    this.$nextTick(() => {
      this.setDocumentContent(this.inputValue.value);
    });
  },
};
</script>

<style lang="scss" scoped>
.document-field {
  .remove-file {
    max-width: 10em;
  }

  .video-player {
    max-width: 100%;
    max-height: 50em;
  }

  .file-link {
    color: #4e0695 !important;
  }
}
</style>
