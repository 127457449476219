import Category from './Category.js';
// SafetyHazard model
export default class Hazard {
  #warning = 4;
  #danger = 8;
  id;
  nano_id;
  category;
  category_name;
  name;
  pre;
  control_measure;
  post;
  responsibility;

  constructor({
    id = null,
    nano_id = null,
    category = null,
    category_id = null,
    category_name = null,
    name = '',
    pre = 0,
    control_measure = '',
    post = 0,
    responsibility = '',
  }) {
    this.id = id;
    this.nano_id = nano_id;
    this.category = category;
    this.category_id = category_id;
    this.category_name = category_name;
    this.name = name;
    this.pre = pre;
    this.control_measure = control_measure;
    this.post = post;
    this.responsibility = responsibility;
  }

  static getHeadings() {
    return [
      { name: 'Category', style: 'width: 15%' },
      { name: 'Hazard', style: 'width: 15%' },
      { name: 'Pre-Risk Score', style: 'width: 8%' },
      { name: 'Control Measure', style: 'width: 30%' },
      { name: 'Post-Risk Score', style: 'width: 8%' },
      { name: 'Responsible Person', style: 'width: 10%' },
      { name: 'Actions', style: 'width: 24%' },
    ];
  }

  getScorePreStyle() {
    return this.calculateStyling(this.pre);
  }

  getScorePostStyle() {
    return this.calculateStyling(this.post);
  }

  calculateStyling(value) {
    let textClass = 'p-1 text-white bg-';
    if (value < this.#warning) return `${textClass}success`;
    if (value >= this.#warning && value < this.#danger)
      return `${textClass}warning`;
    if (value >= this.#danger) return `${textClass}danger`;
  }

  calculateScore(consequence, likelihood) {
    const consequenceScore = {
      'Negligible': 1,
      'Minor': 2,
      'Moderate': 3,
      'Major': 4,
      'Catastrophic': 5,
    }[consequence];

    const likelihoodScore = {
      'Rare': 1,
      'Unlikely': 2,
      'Possible': 3,
      'Likely': 4,
      'Almost Certain': 5,
    }[likelihood];

    if (!consequenceScore || !likelihoodScore) {
      return null;
    }

    return consequenceScore * likelihoodScore;
  }
}
