<script lang="ts" setup>
import { ErrorBag } from '../business-model/form';
import makeId from '../local-id.mjs';
import { onBeforeMount, ref, computed } from 'vue';

const props = withDefaults(
  defineProps<{
    label: string;
    name?: string;
    modelValue?: boolean | 1 | 0;
    value?: boolean | 1 | 0;
    errorBag?: ErrorBag;
    required?: boolean;
    disabled?: boolean;
    isSwitch?: boolean;
  }>(),
  {
    labelBefore: false,
    required: false,
  }
);

const emit = defineEmits(['update:modelValue', 'input']);
const id = ref(props.name);

onBeforeMount(updateId);

const inputError = computed(() => {
  if (!props.name || !props.errorBag || !(props.name in props.errorBag)) {
    return null;
  }

  return Array.isArray(props.errorBag[props.name])
    ? props.errorBag[props.name][0]
    : props.errorBag[props.name];
});

const inputValue = computed({
  get: () => props.modelValue || props.value,
  set: (value) => {
    if (props.disabled) {
      return;
    }
    emit('update:modelValue', value);
    emit('input', value);
  },
});

function updateId() {
  id.value = props.name + '-' + makeId();
}
</script>

<template>
  <div>
    <div
      class="d-flex align-items-center"
      :class="{
        'form-check': !isSwitch,
        'form-switch': isSwitch,
      }"
    >
      <input
        class="form-check-input"
        type="checkbox"
        :disabled="disabled"
        :id="id"
        v-model="inputValue"
      />
      <label class="form-check-label ms-2 mt-1" :for="id">
        {{ label }} <sup v-if="required" class="text-danger">*</sup>
      </label>
    </div>
    <div v-if="inputError" class="invalid-feedback">
      {{ inputError }}
    </div>
  </div>
</template>
