export type UnitConversion = {
  input_unit: string;
  output_unit: string;
  conversion_factor: number;
  matrices: string[];
  is_default?: boolean;
};

export function safeMultiply(a: number, b: number, maxDecimals: number = 8) {
  const scaleFactor = Math.pow(10, maxDecimals);

  const scaledA = Math.round(a * scaleFactor);
  const scaledB = Math.round(b * scaleFactor);

  const scaledResult = scaledA * scaledB;

  return scaledResult / Math.pow(scaleFactor, 2);
}

const unitKeyMap = {
  'ug/l': 'µg/L', // Micrograms per liter
  'ug/kg': 'µg/kg', // Micrograms per kilogram
  'ug/m3': 'µg/m3',
  'mg/l': 'mg/L', // Milligrams per liter
  'ng/ml': 'ng/mL', // Nanograms per milliliter
  'pg/ml': 'pg/mL', // Picograms per milliliter
  m: 'M', // Molar (moles per liter)
  mm: 'mM', // Millimolar
  um: 'µM', // Micromolar
  nm: 'nM', // Nanomolar
  'iu/l': 'IU/L', // International Units per Liter
  'cfu/ml': 'CFU/mL', // Colony Forming Units per Milliliter
};

export const getUnitByKey = (key: string) => {
  return unitKeyMap[key] || key;
};
