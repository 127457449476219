import useLegacyStore from './useLegacyStore';

export default function useProjectId() {
  const {
    state: { project },
  } = useLegacyStore();

  if (!project) {
    throw 'The project is null';
  }

  return project.project_id;
}
