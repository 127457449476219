import { getCurrentInstance } from 'vue';

/**
 * @deprecated Use @component-library/EventBus instead.
 */
export default function useLegacyRootBus() {
  console.warn('useLegacyRootBus is deprecated. Use @component-library/EventBus instead.');

  // @ts-expect-error
  const { proxy: app } = getCurrentInstance();
  // $root will not work in Vue 3 (99% sure)
  return app.$root;
}
