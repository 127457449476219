<template>
  <div v-click-outside="hideMenu" class="form-group">
    <label class="form-label" for="dropdown">
      {{ field.label }}
      <sup v-if="!!field.is_required" class="text-danger">*</sup>
      <a
        v-if="
          value &&
          !field.options.display_as_input &&
          !field.options.has_multiple
        "
        href="#"
        @click.prevent="value = null"
      >
        Clear selection
      </a>
    </label>

    <template v-if="field.options.display_as_input">
      <div
        class="input-group flex-nowrap"
        :class="{
          'mb-2': !isMenuVisible,
        }"
      >
        <input
          v-if="!field.options.has_multiple"
          type="text"
          class="form-control cursor-pointer"
          ref="dropdownInput"
          :class="{
            'border-danger': !!field.is_required && !value,
          }"
          name="dropdown-replace"
          :value="value"
          :required="!!field.is_required"
          :disabled="isDisabled"
          autocomplete="off"
          @focus="removeFocus"
          @click.prevent="toggleMenu"
        />
        <InputTag
          v-else
          class="flex-grow-1"
          :value="value"
          :readOnly="true"
          :isDisabled="isDisabled"
          @click="toggleMenu"
        />

        <button
          v-if="isAddButtonVisible"
          class="btn btn-primary"
          type="button"
          @click.prevent="handleOptionAdd"
        >
          <i class="fal fa-plus"></i>
        </button>
        <button
          class="btn btn-danger"
          type="button"
          :disabled="isDisabled"
          @click.prevent="setValue(null)"
        >
          <i class="fal fa-times"></i>
        </button>
      </div>

      <div v-if="isMenuVisible" class="dropdown-content">
        <input
          v-if="isSearchable"
          ref="searchInput"
          type="text"
          class="form-control border-0"
          v-model.trim="search"
          :placeholder="searchInputPlaceholder"
        />

        <button
          class="btn btn-light w-100 position-sticky top-0"
          style="
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          "
          @click="hideMenu"
        >
          Close
        </button>
        <div
          class="d-flex align-items-center dropdown-option clickable"
          v-for="(option, optionIndex) in searchResult"
          :key="optionIndex"
          @click.stop="selectOption(option)"
        >
          <input
            v-if="field.options.has_multiple"
            type="checkbox"
            class="me-2"
            :value="option"
            v-model="value"
          />
          <a class="flex-grow-1">{{ option }}</a>
        </div>
        <a v-if="searchResult && !searchResult.length" class="text-muted">
          No options for "{{ search }}"
        </a>
      </div>
    </template>
    <template v-else>
      <div class="dropdown-container">
        <div
          v-for="(option, optionIndex) in options"
          :key="optionIndex"
          class="d-flex form-check"
          @click.stop="selectOption(option)"
        >
          <input
            class="form-check-input"
            :type="field.options.has_multiple ? 'checkbox' : 'radio'"
            :value="option"
            :disabled="isDisabled"
            :checked="isSelected(value, option)"
          />

          <label class="form-check-label ms-2">
            <template v-if="getOptionIcon(option)">
              <img :src="getOptionIcon(option)" width="16px" height="16px" />
            </template>
            {{ option }}
          </label>
        </div>
      </div>

      <small v-if="!!field.is_required && !value" class="d-block text-danger">
        You must select at least one option.
      </small>

      <div
        v-if="field.options.is_add_enabled"
        class="input-group input-group-sm mt-2"
      >
        <input
          type="text"
          class="form-control"
          placeholder="Add custom value..."
          v-model="search"
        />
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="handleOptionAdd"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import InputTag from '@component-library/components/InputTag.vue';
import InputCheckbox from '@component-library/components/InputCheckbox.vue';
import * as bl from '../../business-logic';
import makeId from '../../local-id.mjs';

export default {
  components: {
    InputTag,
    InputCheckbox,
  },
  props: {
    field: Object,
    inputValue: Object,
    defaultValue: {
      required: false,
      default: false,
    },
    groupIndex: {
      required: false,
      default: null,
    },
    isAutoAssignEnabled: Boolean,
    isAutoAssignActive: Boolean,
    isDisabled: Boolean,
  },
  inject: ['formContext'],
  data: () => ({
    search: null,
    isMenuVisible: false,
    isDefaultInputValue: false,
    idPrefix: makeId(),
  }),
  computed: {
    searchInputPlaceholder() {
      const { is_add_enabled } = this.field.options;
      return !is_add_enabled
        ? 'Search options...'
        : 'Search options or add a new option...';
    },
    isSearchable() {
      const { is_add_enabled } = this.field.options;
      // The search input is also used to add new options on the fly.
      return this.options.length > 5 || is_add_enabled;
    },
    isAddButtonVisible() {
      const { is_add_enabled } = this.field.options;
      return (
        is_add_enabled &&
        this.search &&
        !bl.dropdown.checkIsOptionDuplicate(this.options, this.search)
      );
    },
    value: {
      get() {
        const { value } = this.inputValue;
        const { has_multiple } = this.field.options;
        if (has_multiple) {
          if (Array.isArray(value)) {
            return value;
          } else if (value) {
            return [value];
          } else {
            return [];
          }
        } else {
          return Array.isArray(value) ? value[0] : value;
        }
      },
      set(updated) {
        const _isDefaultInputValue = this.isDefaultInputValue;
        if (_isDefaultInputValue) {
          this.isDefaultInputValue = false;
        }
        this.$root.$emit('updateInputValue', {
          inputValue: { ...this.inputValue, value: updated },
          field: this.inputValue.template_field_id,
          sectionIndex: this.inputValue.template_section_index,
          templateTabId: this.inputValue.template_tab_id,
          isDefaultInputValue: _isDefaultInputValue,
        });
      },
    },
    options() {
      let result = [];
      const { options = [], groups = [] } = this.field.options ?? {};
      if (
        Number.isInteger(this.groupIndex) &&
        Array.isArray(groups[this.groupIndex]?.items) &&
        groups[this.groupIndex].items.length
      ) {
        result = groups[this.groupIndex].items;
      } else if (Array.isArray(options)) {
        result = options;
      }

      // handle case where offline or public form updated dropdown options but request wasn't saved in DB.
      const { value } = this.inputValue;
      if (value !== null) {
        const { has_multiple = false } = this.field.options ?? {};
        let selectedOptions = [];
        if (has_multiple) {
          if (Array.isArray(value)) {
            selectedOptions = value;
          } else {
            selectedOptions = [value];
          }
        } else {
          selectedOptions = [Array.isArray(value) ? value[0] : value];
        }
        for (const so of selectedOptions) {
          if (!result.includes(so)) {
            result.push(so);
          }
        }
      }

      return result;
    },
    searchResult() {
      return this.search
        ? this.options.filter((o) =>
            o.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.options;
    },
  },
  watch: {
    defaultValue: {
      handler(newValue) {
        if (
          !this.value ||
          (this.isAutoAssignEnabled && this.isAutoAssignActive)
        ) {
          this.isDefaultInputValue = true;
          this.setValue(newValue);
        }
      },
      immediate: true,
    },
    isAutoAssignActive(newValue) {
      if (newValue) {
        this.setValue(this.defaultValue);
      }
    },
    groupIndex() {
      const { defaultValue } = this;
      this.setValue(defaultValue ? defaultValue : null);
    },
  },
  methods: {
    removeFocus() {
      this.$refs.dropdownInput.blur();
    },
    async toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
      if (this.isMenuVisible) {
        await this.$nextTick();
        this.$refs.searchInput?.focus();
      }
    },
    hideMenu() {
      this.isMenuVisible = false;
    },
    isSelected(v1, v2) {
      if (Array.isArray(v1)) {
        return v1.includes(v2);
      }
      if (v1 && typeof v1 === 'object') {
        console.error('v1 is an object?', v1);
        return false;
      }
      if (typeof v1 === 'string') {
        v1 = v1.toLowerCase().trim();
      }
      if (typeof v2 === 'string') {
        v2 = v2.toLowerCase().trim();
      }
      if (v1 === v2) {
        return true;
      }
      return v1 === v2;
    },
    setValue(value) {
      this.value = value;
      this.hideMenu();
      this.search = null;
    },
    selectOption(option) {
      const { has_multiple } = this.field.options;
      if (has_multiple) {
        if (!this.value.includes(option)) {
          this.value = [...this.value, option];
        } else {
          this.value = this.value.filter((item) => item !== option);
        }
      } else {
        this.value = option;
        this.toggleMenu();
      }
    },
    async handleOptionAdd() {
      const newOption = this.search;
      this.search = null;

      const { options, groups } = this.field.options;
      let nextOptions = [...options];
      let nextGroups = [...groups];

      // An option which doesn't exist in the group may already exist.
      if (!bl.dropdown.checkIsOptionDuplicate(nextOptions, newOption)) {
        nextOptions.push(newOption);
      }

      let group;
      if (
        Number.isInteger(this.groupIndex) &&
        ((group = groups[this.groupIndex]), group?.items.length) &&
        !bl.dropdown.checkIsOptionDuplicate(group.items, newOption)
      ) {
        const nextItems = [...group.items, newOption];
        const nextGroup = { ...group, items: nextItems };
        nextGroups.splice(this.groupIndex, 1, nextGroup);
      }

      await this.formContext.updateField(this.field.id, 'options', {
        ...this.field.options,
        options: nextOptions,
        groups: nextGroups,
      });

      this.selectOption(newOption);
    },
    getOptionIcon(option) {
      return bl.dropdown.getOptionIconSrc(option, this.field);
    },
  },
};
</script>

<style scoped>
/* Dropdown Content*/
.dropdown-content {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 200;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 25rem;
  overflow-y: scroll;
}

/* Links inside the dropdown */
.dropdown-content .dropdown-option {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content .dropdown-option:hover {
  background-color: #f4f4f4;
}

.dropdown-container {
  max-height: 150px;
  overflow-y: auto;
}
</style>
