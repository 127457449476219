<template>
  <div>
    <label id="file">
      {{ field.label }}<sup class="text-danger" v-if="isRequired">*</sup>
      <template v-if="!isReadonly">
        <br />
        <small class="text-muted">(ogg,mp4,webm)</small>
      </template>
    </label>

    <div class="mt-2">
      <template v-if="!src">
        <div class="d-flex">
          <ButtonSelectFile
            v-if="isMobile && !isReadonly"
            @triggerUpload="captureVideo"
            :hasError="isRequired && !value.value"
            class="me-2"
          >
            Capture Video
          </ButtonSelectFile>
          <ButtonSelectFile
            v-if="!isReadonly"
            @triggerUpload="fileVideo"
            :hasError="isRequired && !value.value"
            :isUploading="loading"
          >
            Upload Video
          </ButtonSelectFile>
        </div>
      </template>

      <input
        type="file"
        ref="videoFile"
        @change="fileChange"
        class="form-control-file d-none"
        :accept="supportedTypes"
      />

      <div
        v-if="isInputValueUnset && isReadonly && !defaultValue"
        class="alert alert-danger mb-0 mt-2"
      >
        No video found.
      </div>

      <div v-if="src">
        <div v-if="value.value2" class="mb-2">
          <span>{{ value.value2 }}</span>
          <small v-if="fileCheck & !isReadonly" class="d-block">{{
            fileSize
          }}</small>
        </div>

        <video
          v-if="canPlay"
          :id="videoId"
          controls
          ref="videoPlayer"
          class="video-player"
          playsinline
          oncontextmenu="return false;"
        >
          <source :src="src" :type="mimeType" />
          Your device does not support the
          <code>video</code> element.
        </video>
        <div v-else class="alert alert-warning">
          Device does not support playing <code>{{ mimeType }}</code> file
          types.
          <a :href="src" download class="text-warning">
            <i class="fas fa-file-download"></i> Download here
          </a>
        </div>

        <button
          v-if="!isReadonly"
          type="button"
          class="btn btn-outline-danger w-100 mt-2"
          @click.prevent="clearFile"
        >
          Remove File
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as bm from '../../business-model';
import * as bl from '../../business-logic';
import * as utils from '../../utils';
import ButtonSelectFile from '../../components/ButtonSelectFile.vue';
import makeId from '../../local-id.mjs';

export default {
  props: {
    field: {
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
    inputValues: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isMobile: utils.checkIsMobile(),
    loading: false,
    inputIndex: null,
    canPlay: true,
    supportedTypes: 'video/mp4,video/ogg,video/webm',
    videoId: makeId(),
  }),
  components: {
    ButtonSelectFile,
  },
  inject: {
    formContext: { default: null },
    updateVideoPlayer: { default: (fieldId, sectionIndex, update) => {} },
  },
  computed: {
    isUsedInPublicForm() {
      return this.formContext?.type === bm.common.FORM_CONTEXT_TYPE_PUBLIC_FORM;
    },
    isInputValueUnset() {
      return bl.input_value.checkIsInputValueUnset(this.value);
    },
    defaultValue() {
      const { default: defaultValue } = this.field.options;
      return defaultValue;
    },
    isReadonly() {
      return this.field.options.is_readonly;
    },
    isRequired() {
      return this.field?.is_required || false;
    },
    src() {
      if (this.value?.value instanceof Blob) {
        return URL.createObjectURL(this.value.value);
      } else if (typeof this.value?.value === 'string') {
        return `/api/images/value/${
          this.value.project_id ?? this.formContext?.project?.project_id
        }/${this.value.value}`;
      }
      return false;
    },
    fileCheck() {
      return this.value?.value instanceof Blob;
    },
    fileSize() {
      let number = this.value?.value?.size;
      if (number < 1024) {
        return number + ' bytes';
      } else if (number >= 1024 && number < 1048576) {
        return (number / 1024).toFixed(1) + ' KB';
      } else if (number >= 1048576) {
        return (number / 1048576).toFixed(1) + ' MB';
      }
    },
    mimeType() {
      if (this.value?.value instanceof Blob) {
        return this.value.value.type;
      } else if (typeof this.value?.value === 'string') {
        return `video/${this.value.value2.split('.').at(-1).toLowerCase()}`;
      }
      return null;
    },
  },
  methods: {
    updateInputValue(value, value2) {
      this.$root.$emit('updateInputValue', {
        inputValue: { ...this.value, value, value2 },
        field: this.value.template_field_id,
        sectionIndex: this.value.template_section_index,
        templateTabId: this.value.template_tab_id,
      });
    },
    fileChange(e) {
      const file = e.target.files[0];
      if (
        this.supportedTypes.includes(file.type) < 0 ||
        this.supportedTypes.includes(file.type) === false
      ) {
        this.$toastStore.error('File type is not supported for video upload');
        return;
      }
      if (file.size / 1048576 > 99) {
        this.$toastStore.error('File size must be under 100MB');
        return;
      }
      this.updateInputValue(file, file.name);
      e.target.value = '';
    },
    clearFile() {
      this.updateInputValue(null, null);
    },
    captureVideo() {
      this.$emit('clickVideo', {
        useVideo: true,
        inputIndex: this.inputIndex,
      });
    },
    fileVideo() {
      this.$refs.videoFile.click();
    },
  },
  watch: {
    src: {
      async handler(newValue) {
        if (newValue) {
          this.canPlay = true;
          await this.$nextTick();

          if (this.isReadonly && this.isUsedInPublicForm) {
            if (this.$refs.videoPlayer.controlsList) {
              this.$refs.videoPlayer.controlsList.add('nodownload');
              this.$refs.videoPlayer.controlsList.add('noplaybackrate');
            }
            this.removeVideoPlaybackLimit = utils.addVideoPlaybackLimit(
              this.$refs.videoPlayer
            );

            const { isWatchingFinished } = this.updateVideoPlayer(
              this.field.id,
              this.value.template_section_index,
              {
                id: this.videoId,
              }
            );
            if (!isWatchingFinished) {
              this.$refs.videoPlayer.addEventListener('ended', () => {
                this.updateVideoPlayer(
                  this.field.id,
                  this.value.template_section_index,
                  {
                    isWatchingFinished: true,
                  }
                );
              });
            }
          }

          this.canPlay = !!this.$refs.videoPlayer.canPlayType(this.mimeType);
        } else {
          this.canPlay = false;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if ((this.isInputValueUnset || this.isReadonly) && this.defaultValue) {
      const { fileName, assetName } = this.defaultValue;
      this.updateInputValue(assetName, fileName);
      await this.$nextTick();
    }

    this.inputIndex = this.inputValues.findIndex(
      (item) => JSON.stringify(item) === JSON.stringify(this.value)
    );
  },
  beforeDestroy() {
    if (typeof this.removeVideoPlaybackLimit === 'function') {
      this.removeVideoPlaybackLimit();
    }
    URL.revokeObjectURL(this.src);
  },
};
</script>

<style scoped>
.remove-file {
  max-width: 10em;
}

.video-player {
  max-width: 100%;
  max-height: 50em;
}
</style>
