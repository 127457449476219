import { defineStore } from 'pinia';
import useApi from '../composables/useApi';
import { computed, ref } from 'vue';
import { User } from '../user';

export const useCompanyUsersStore = defineStore('company-users', () => {
    const companyUsers = ref<User[]>([]);
    const isLoading = ref(false);
    async function loadCompanyUsers() {
        if (isLoading.value) {
            throw new Error('Already loading company users');
        }
        const api = useApi();

        isLoading.value = true;
        try {
            const { data } = await api.get('/company/users');
            companyUsers.value = data.users;
        } catch (e) {
            throw e;
        } finally {
            isLoading.value = false;
        }
    }

    return {
        isLoading,
        hasUsers: computed(() => companyUsers.value.length > 0),
        companyUsers,
        loadCompanyUsers,
    };
});