export default function stringToSlug(strA, str) {
  if (strA !== null && strA !== '' && strA !== undefined) {
    return stringToSlug(null, strA);
  } else if (str === null || str === undefined) {
    return str;
  }

  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç-';
  var to = 'aaaaeeeeiiiioooouuuunc_';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 _]/g, '') // remove invalid chars
    .replace(/\s+/g, '_') // collapse whitespace and replace by _
    .replace(/_+/g, '_'); // collapse dashes

  // Trim trailing _
  str = str.replace(/_+$/, '');

  return str;
}
