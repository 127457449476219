<script lang="ts" setup>
import { computed, nextTick, onBeforeMount, ref } from 'vue';
import { ErrorBag } from '../business-model/form';
import makeId from '../local-id.mjs';
import { truncateWithEllipsis } from '../utils';

const props = defineProps<{
  label?: string | null;
  placeholder?: string;
  name?: string;
  modelValue?: string | number | object | null;
  value?: string | number | object | null;
  errorBag?: ErrorBag;
  required?: boolean;
  small?: boolean;
  disabled?: boolean;
  options?:
    | string[]
    | { label: string; value: string | number; disabled?: boolean }[];
}>();

const emit = defineEmits<{
  (event: 'input', value?: string | number | null | object): void;
  (event: 'change', value?: string | number | null | object): void;
  (event: 'update:modelValue', value?: string | number | null | object): void;
}>();

const id = ref(props.name);

onBeforeMount(updateId);

const inputError = computed(() => {
  if (!props.name || !props.errorBag) {
    return null;
  }
  if (!(props.name in props.errorBag)) {
    return null;
  }
  return Array.isArray(props.errorBag[props.name])
    ? props.errorBag[props.name][0]
    : props.errorBag[props.name];
});

const inputValue = computed({
  get: () => props.modelValue || props.value,
  set: (value) => {
    emit('update:modelValue', value);
    emit('input', value);
  },
});

function updateId() {
  id.value = props.name + '-' + makeId();
}

function changeHandler() {
  nextTick(() => {
    emit('change', inputValue.value);
  });
}
</script>

<template>
  <div>
    <label
      v-if="label"
      class="form-label"
      :class="small ? 'text-sm' : ''"
      :for="id"
    >
      {{ label }} <sup class="text-danger" v-if="required">*</sup>
    </label>
    <select
      class="form-select"
      :class="{ 'is-invalid': inputError, 'form-select-sm': small }"
      :id="id"
      :required="required"
      :name="name"
      :disabled="disabled"
      v-model="inputValue"
      @change="changeHandler"
    >
      <option
        v-if="placeholder && (inputValue === null || inputValue === undefined)"
        :value="inputValue"
        disabled
      >
        {{ placeholder }}
      </option>
      <template v-for="option in options">
        <option v-if="typeof option === 'string'" :value="option">
          {{ option ? truncateWithEllipsis(String(option), 100) : '' }}
        </option>
        <option
          v-else-if="typeof option === 'object'"
          :value="option.value"
          :disabled="option.disabled"
        >
          {{
            option.label ? truncateWithEllipsis(String(option.label), 100) : ''
          }}
        </option>
      </template>
      <slot />
    </select>
    <div v-if="inputError" class="invalid-feedback">
      {{ inputError }}
    </div>
  </div>
</template>
