<template>
  <div class="form-group" :class="getStyle(value)">
    <label class="form-label" for="number">
      {{ field.label }}
      <sup class="text-danger" v-if="field.is_required">*</sup>
    </label>
    <div class="input-group">
      <span class="input-group-text" v-if="field.options.prefix">
        {{ field.options.prefix }}
      </span>
      <input
        ref="numberInput"
        type="number"
        step="any"
        :disabled="disabled"
        :class="[
          'form-control',
          {
            'border-danger': field.is_required && [null, ''].includes(value),
          },
        ]"
        v-model="value"
        name="number"
        @blur="handleBlur"
        @wheel.prevent
      />
      <span class="input-group-text" v-if="field.options.unit">
        {{ field.options.unit }}
      </span>
      <span
        v-if="hasTolerance"
        class="input-group-text"
        :class="{
          'bg-success': passesTolerance,
          'bg-warning text-light': !passesTolerance,
        }"
      >
        <i
          class="fas fa-fw"
          :class="{
            'fa-check-circle': passesTolerance,
            'fa-times-circle': !passesTolerance,
          }"
        ></i>
      </span>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import { checkIsInputValueEmpty } from '../../business-logic/input-value';
import { fieldHasTolerance, inputValuePasses } from '../../utils/tolerance';
import { safeMultiply } from '../../utils/unit-conversion';
import { waitFor } from '../../utils/wait-for';
import Hazard from '../classes/Hazard.js';

export default {
  props: {
    field: Object,
    inputValue: Object,
    sectionIndex: Number,
    isSafety: {
      required: false,
      default: () => false,
    },
    lastValue: {
      type: Object,
      required: false,
    },
    inputValues: Array,
  },
  inject: ['formContext'],
  data: () => ({
    isDefaultInputValue: false,
    isWaiting: false,
  }),
  computed: {
    disabled() {
      return (
        this.field.options.is_readonly || this.inputValue?.options?.disabled
      );
    },
    value: {
      get() {
        if (checkIsInputValueEmpty(this.inputValue, this.field)) {
          return null;
        }

        const _value = parseFloat(this.inputValue.value);
        return safeMultiply(_value, 1);
      },
      set(value) {
        if (!this.isWaiting) {
          this.isWaiting = true;
        }
        this.setValueDebounced(value);
      },
    },
    hasTolerance() {
      return fieldHasTolerance(this.field);
    },
    passesTolerance() {
      if (!this.hasTolerance) {
        return false;
      }
      return inputValuePasses(this.field, this.inputValue, this.inputValues);
    },
  },
  methods: {
    getDefaultValue() {
      const { default_value: startingValue = null, increment = null } =
        this.field.options ?? {};
      if (startingValue === null && increment === null) {
        return null;
      }

      const { value: _lastValue = null } = this.lastValue ?? {};
      if (_lastValue !== null && increment !== null) {
        return (
          Math.round(
            (parseFloat(_lastValue) + parseFloat(increment)) * 1000000
          ) / 1000000
        );
      }

      if (startingValue !== null) {
        return Math.round(parseFloat(startingValue) * 1000000) / 1000000;
      }

      return null;
    },
    setValue(value) {
      const _isDefaultInputValue = this.isDefaultInputValue;
      if (_isDefaultInputValue) {
        this.isDefaultInputValue = false;
      }
      this.$root.$emit('updateInputValue', {
        inputValue: { ...this.inputValue, value },
        field: this.inputValue.template_field_id,
        sectionIndex: this.inputValue.template_section_index,
        templateTabId: this.inputValue.template_tab_id,
        isDefaultInputValue: _isDefaultInputValue,
      });
    },
    getStyle(value) {
      if (value === null || !this.inputValue?.options?.is_health_safety) {
        return null;
      }
      const hazard = new Hazard({});
      return hazard.calculateStyling(value);
    },
    async handleBlur() {
      if (this.isWaiting) {
        await waitFor(() => !this.isWaiting);
        await this.$nextTick();
      }
      if (checkIsInputValueEmpty(this.inputValue, this.field)) {
        this.isDefaultInputValue = true;
        const defaultValue = this.getDefaultValue();
        this.setValue(defaultValue);
      }
    },
  },
  created() {
    this.setValueDebounced = _debounce((value) => {
      this.setValue(value);
      if (this.isWaiting) {
        this.isWaiting = false;
      }
    }, 1000);
  },
  watch: {
    isWaiting(newValue) {
      this.formContext.setIsBusy(newValue);
    },
  },
  mounted() {
    if (this.field.options?.increment) {
      this.$refs.numberInput.step = this.field.options.increment;
    }

    if (checkIsInputValueEmpty(this.inputValue, this.field)) {
      this.isDefaultInputValue = true;
      const defaultValue = this.getDefaultValue();
      this.setValue(defaultValue);
    }
  },
};
</script>
