<script lang="ts" setup>
import { useToastStore } from '../store/toasts';

const toast = useToastStore();
</script>

<template>
  <div class="toast-container">
    <transition-group name="toast" tag="div">
      <div
        v-for="aToast of toast.toasts"
        :key="aToast.id"
        data-test="toast"
        class="cursor-pointer mb-2 px-3 py-2 position-relative rounded text-white"
        :class="toast.getBackgroundColor(aToast)"
        @click.prevent="toast.dismissToast(aToast.id)"
      >
        {{ aToast.message }}
        <div
          class="noty_progressbar"
          style="transition: width 0ms linear 0s; width: 100%"
        ></div>
      </div>
    </transition-group>
  </div>
</template>

<style scoped>
.toast-enter-active,
.toast-leave-active {
  transition: all 0.5s ease;
}

.toast-enter,
.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.toast-container {
  pointer-events: unset !important;
  position: fixed;
  z-index: 99999999;

  bottom: 5%;
  left: 50%;
  width: 325px;
  transform: translate(calc(-50% - 0.5px)) translateZ(0) scale(1, 1);
}

.cursor-pointer {
  cursor: pointer;
}
</style>
