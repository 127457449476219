<script setup lang="ts">
import { computed, inject, onMounted, ref, watch } from 'vue';
import {
  getNumberTitle,
  getPrimaryFieldTitle,
} from '../../business-logic/section';
import useApi from '../../composables/useApi';
import useItemsFromAppTitle, {
  mapFormContextType,
} from '../../composables/useItemsFromAppTitle';
import useLegacyRootBus from '../../composables/useLegacyRootBus';
import useProjectId from '../../composables/useProjectId';
import { FieldTypeIds } from '../../fields';
import { App, GatherField, InputValue, Item, Section } from '../../gather';
import makeId from '../../local-id.mjs';
import { captureException } from '../../sentry';
import { useCollectionStore } from '../../store/collection';
import { useToastStore } from '../../store/toasts';

type LinkedItemSelectValue = number | null;
type LinkedSection = {
  index: number;
  title: string;
};
type LinkedSectionSelectValue = number | null;

const props = defineProps<{
  app: App;
  section: Section;
  field: GatherField;
  inputValue: InputValue;
  inputValues: InputValue[];
  item: Item;
  allFields: GatherField[];
  sectionIndex: number;
}>();

const legacyRootBus = useLegacyRootBus();
const collectionStore = useCollectionStore();
const toastStore = useToastStore();
const api = useApi();
const projectId = useProjectId();

const formContext = inject<any>('formContext');
const sampleModal = inject<any>('sampleModal', null);

const linkedItemSelectId = `item-select-${makeId()}`;
function useLinkedItems() {
  const { isLoading, items: linkedItems } = useItemsFromAppTitle(
    mapFormContextType(formContext.type),
    props.app.title
  );
  return {
    isLoading,
    linkedItems: computed<Item[]>(() =>
      props.section.is_repeatable
        ? linkedItems.value
        : linkedItems.value.filter((li) => li.id !== props.item.id)
    ),
  };
}
const { isLoading, linkedItems } = useLinkedItems();
const linkedItemSelectValue = computed<LinkedItemSelectValue>({
  get() {
    return props.inputValue.value !== null
      ? parseInt(props.inputValue.value as string)
      : null;
  },
  set(value) {
    const {
      template_field_id: fieldId,
      template_section_index: sectionIndex,
      template_tab_id: appId,
    } = props.inputValue;
    legacyRootBus.$emit('updateInputValue', {
      inputValue: {
        ...props.inputValue,
        value,
      },
      field: fieldId,
      sectionIndex,
      templateTabId: appId,
    });
  },
});

function gotoLinkedItem() {
  if (!linkedItemSelectValue.value) {
    throw new Error('gotoLinkedItem: No option selected');
  }
  if (!sampleModal) {
    throw new Error('gotoLinkedItem: No sample modal');
  }
  sampleModal.gotoItem(linkedItemSelectValue.value);
}

const linkedSections = ref<LinkedSection[]>([]);
const primaryField = computed<GatherField | null>(() => {
  const { section } = props;
  const fields = props.allFields.filter(
    (f) => f.template_section_id === section.id
  );
  // @ts-ignore
  fields.sort((f1, f2) => f1.order - f2.order);
  return (
    fields.find((f) => f.id === section.primary_field_id) ??
    fields.find((f) => f.field_type_id !== FieldTypeIds.COPY_DATA_LINK) ??
    null
  );
});
const linkedSectionSelectValue = computed<LinkedSectionSelectValue>({
  get() {
    return props.inputValue.value2 !== null
      ? parseInt(props.inputValue.value2 as string, 10)
      : null;
  },
  set(value) {
    const {
      template_field_id: fieldId,
      template_section_index: sectionIndex,
      template_tab_id: appId,
    } = props.inputValue;
    legacyRootBus.$emit('updateInputValue', {
      inputValue: {
        ...props.inputValue,
        value2: value !== null ? String(value) : value,
      },
      field: fieldId,
      sectionIndex,
      templateTabId: appId,
    });
  },
});
async function loadLinkedSections(sampleId: number): Promise<LinkedSection[]> {
  const result: LinkedSection[] = [];
  collectionStore.startBusy();
  try {
    const { data: pfivs } = await api.get(
      'gather/section/primary-field-input-values',
      {
        params: {
          project_id: projectId,
          sample_id: sampleId,
          section_id: props.section.id,
        },
      }
    );
    const {
      sectionIndex,
      section: { is_number_used_as_title: isNumberUsedAsTitle },
    } = props;
    for (let i = 0; i < pfivs.length; i++) {
      if (sampleId === props.item.id && i === sectionIndex) {
        continue;
      }

      const n = i + 1;
      const title = isNumberUsedAsTitle
        ? getNumberTitle(n)
        : await new Promise<string>((resolve) => {
            const pft = getPrimaryFieldTitle(
              primaryField.value,
              pfivs[i],
              n,
              resolve
            ) as string;
            if (primaryField.value?.field_type_id !== FieldTypeIds.REFERENCE) {
              resolve(pft);
            }
          });
      result.push({ index: i, title });
    }
  } catch (err) {
    console.log(err);
    captureException(err);
    toastStore.error('Something went wrong, please try it again later.');
  } finally {
    collectionStore.stopBusy();
  }
  return result;
}
watch(linkedItemSelectValue, async (newValue) => {
  if (!newValue) {
    linkedSections.value = [];
    linkedSectionSelectValue.value = null;
    return;
  }

  linkedSections.value = await loadLinkedSections(newValue);
  linkedSectionSelectValue.value = linkedSections.value.length > 0 ? 0 : null;
});

function findTargetInputValue(sourceInputValue: InputValue) {
  const {
    item: { id: sampleId },
    sectionIndex,
  } = props;
  const {
    template_tab_id: appId,
    template_section_id: sectionId,
    template_field_id: fieldId,
  } = sourceInputValue;
  return props.inputValues.find(
    (iv) =>
      iv.sample_id === sampleId &&
      iv.template_tab_id === appId &&
      iv.template_section_id === sectionId &&
      iv.template_field_id === fieldId &&
      iv.template_section_index === sectionIndex
  );
}

function normalizeValue(value) {
  if (typeof value !== 'string') {
    return value;
  }

  try {
    value = JSON.parse(value);
  } catch (e) {}

  return value;
}

async function handleCopy() {
  const {
    app: { id: appId },
    section: { id: sectionId },
  } = props;
  collectionStore.startBusy();
  try {
    const {
      data: { input_values: ivs },
    } = await api.get('gather/section/input-values', {
      params: {
        project_id: projectId,
        tab_id: appId,
        section_id: sectionId,
        sample_ids: [linkedItemSelectValue.value],
        section_index: linkedSectionSelectValue.value,
      },
    });

    let counter = 0;
    const { sectionIndex } = props;
    for (const iv of ivs) {
      const {
        project_id: _projectId,
        template_tab_id: appId,
        template_field_id: fieldId,
        value,
        value2,
        options,
      } = iv;
      const field = props.allFields.find((f) => f.id === fieldId);
      if (
        !field ||
        !(field.options?.isInvolvedInDataCopy ?? true) ||
        field.field_type_id === FieldTypeIds.COPY_DATA_LINK
      ) {
        continue;
      }

      const targetIv = findTargetInputValue(iv);
      legacyRootBus.$emit('updateInputValue', {
        inputValue: {
          ...targetIv,
          // The projectId is required for new input values.
          project_id: _projectId,
          value: normalizeValue(value),
          value2,
          options,
        },
        field: field.id,
        sectionIndex,
        templateTabId: appId,
      });
      counter++;
    }
    toastStore.success(
      `${counter} ${
        counter === 1 ? 'field has ' : 'fields have '
      } been updated using the copied values.`
    );
  } catch (err) {
    console.log(err);
    captureException(err);
    toastStore.error('Something went wrong, please try it again later.');
  } finally {
    collectionStore.stopBusy();
  }
}

onMounted(async () => {
  if (linkedItemSelectValue.value) {
    linkedSections.value = await loadLinkedSections(
      linkedItemSelectValue.value
    );
  }
});
</script>

<template>
  <div class="d-flex flex-column gap-2">
    <div class="form-group">
      <label class="form-label" :for="linkedItemSelectId">
        {{ field.label }}
        <sup class="text-danger" v-if="field.is_required">*</sup>
      </label>

      <div class="input-group d-flex">
        <select
          :id="linkedItemSelectId"
          :class="[
            'form-control',
            {
              'border-danger':
                !!field.is_required && linkedItemSelectValue === null,
            },
          ]"
          :required="!!field.is_required"
          v-model.number="linkedItemSelectValue"
        >
          <option :value="null">Not selected.</option>
          <option
            v-for="li of linkedItems"
            :value="li.id"
            :key="`linked-item-${li.id}`"
          >
            {{ li.custom_title }}
          </option>
        </select>

        <button
          v-if="linkedItemSelectValue && sampleModal"
          class="btn btn-outline-primary"
          :disabled="sampleModal.hasChangedInputValues"
          @click.stop="gotoLinkedItem"
        >
          <i class="fal fa-pencil-alt" />
        </button>
      </div>

      <div
        v-if="!isLoading && linkedItems.length == 0"
        :class="[
          'alert',
          {
            'alert-danger': field.is_required,
            'alert-warning': !field.is_required,
          },
        ]"
      >
        You {{ field.is_required ? '' : 'may' }} need to add a
        {{ app.title }}
        item first.
      </div>
    </div>

    <div
      v-if="
        section.is_repeatable &&
        linkedItemSelectValue &&
        linkedSections.length > 0
      "
      class="form-group"
    >
      <select class="form-control" v-model.number="linkedSectionSelectValue">
        <option
          v-for="ls in linkedSections"
          :key="`linkded-section-${ls.index}`"
          :value="ls.index"
        >
          {{ ls.title }}
        </option>
      </select>
    </div>

    <div>
      <button
        class="btn btn-outline-primary w-100"
        :disabled="!linkedItemSelectValue || linkedSectionSelectValue === null"
        @click="handleCopy"
      >
        <i class="fas fa-copy me-1" />Copy
      </button>
    </div>
  </div>
</template>
