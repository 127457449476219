import * as bm from '../business-model';
import * as utils from '../utils';

function getOptionExtensions(field) {
  return utils.forcePlainObject(field.options.optionExtensions);
}

export function getOptionExtension(field, option) {
  const optionExtensions = getOptionExtensions(field);
  return {
    color: bm.lithology.DEFAULT_COLOR,
    fillPattern: bm.lithology.DEFAULT_FILL_PATTERN,
    ...utils.forcePlainObject(optionExtensions[option]),
  };
}

function getOptionExtensionValue(field, option, name) {
  return getOptionExtension(field, option)[name];
}

export function extendOption(field, option, extension) {
  const optionExtensions = getOptionExtensions(field);
  return {
    ...field.options,
    optionExtensions: {
      ...optionExtensions,
      [option]: { ...getOptionExtension(field, option), ...extension },
    },
  };
}

export function getColor(field, option) {
  return getOptionExtensionValue(
    field,
    option,
    bm.lithology.OPTION_EXTENSION_COLOR
  );
}

export function getFillPattern(field, option) {
  return getOptionExtensionValue(
    field,
    option,
    bm.lithology.OPTION_EXTENSION_FILL_PATTERN
  );
}
