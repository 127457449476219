export function buildSentenceFromField(field) {
  if (!fieldHasTolerance(field)) {
    return false;
  }

  let sentence = '';
  let toleranceString = field.options.tolerance || '0';

  if (field.options.tolerance_basis == 'of_fixed') {
    sentence =
      'Tolerance passes when the value is ' + field.options.tolerance_of;
    if (
      field.options.tolerance_direction != 'above' &&
      field.options.tolerance_direction != 'below'
    ) {
      if (toleranceString == '0') {
        sentence += ' exactly';
      } else {
        sentence += ` +/- ${toleranceString}${
          field.options.tolerance_type == 'percentage' ? '%' : ''
        }`;
      }
    } else {
      sentence += ` or ${field.options.tolerance_direction}`;
    }

    return sentence;
  }

  sentence = `Last ${field.options.tolerance_of} values must be `;
  if (
    field.options.tolerance_direction != 'above' &&
    field.options.tolerance_direction != 'below'
  ) {
    if (toleranceString == '0') {
      sentence += ' exactly the same as each other';
    } else {
      sentence += ` +/- ${toleranceString}${
        field.options.tolerance_type == 'percentage' ? '%' : ''
      } of each other`;
    }
  } else {
    sentence += ` ${field.options.tolerance_direction} the previous values`;
  }

  return sentence;
}

export function fieldHasTolerance(field) {
  return field.options.tolerance_of && field.options.tolerance_basis;
}

function checkFixedTolerance(field, inputValue) {
  if (!field.options.tolerance_of) {
    return false;
  }
  let inputValueFloat = parseFloat(inputValue.value);
  let targetFloat = parseFloat(field.options.tolerance_of);
  if (isNaN(targetFloat)) {
    return false;
  }

  if (field.options.tolerance_direction == 'above') {
    if (inputValueFloat >= targetFloat) {
      return true;
    }
  } else if (field.options.tolerance_direction == 'below') {
    if (inputValueFloat <= targetFloat) {
      return true;
    }
  } else if (!field.options.tolerance) {
    if (targetFloat == inputValueFloat) {
      return true;
    }
  } else {
    let toleranceFloat = parseFloat(field.options.tolerance);
    if (field.options.tolerance_type == 'percentage') {
      if (
        inputValueFloat >= targetFloat * (1 - toleranceFloat / 100) &&
        inputValueFloat <= targetFloat * (1 + toleranceFloat / 100)
      ) {
        return true;
      }
    } else {
      if (
        inputValueFloat >= targetFloat - toleranceFloat &&
        inputValueFloat <= targetFloat + toleranceFloat
      ) {
        return true;
      }
    }
  }

  return false;
}

function checkLastN(field, inputValue, inputValues = []) {
  let inputValueFloat = parseFloat(inputValue.value);
  if (!inputValues?.length) inputValues = [];
  let previousValues = inputValues.filter(
    (iv) =>
      iv.sample_id == inputValue.sample_id &&
      iv.template_field_id == inputValue.template_field_id &&
      iv.template_section_index < inputValue.template_section_index
  );
  for (
    let i = Math.max(
      0,
      previousValues.length - parseFloat(field.options.tolerance_of)
    );
    i < previousValues.length;
    i++
  ) {
    let previousFloat = parseFloat(previousValues[i].value);
    if (field.options.tolerance_direction == 'above') {
      if (inputValueFloat >= previousFloat) {
        continue;
      }
    } else if (field.options.tolerance_direction == 'below') {
      if (inputValueFloat <= previousFloat) {
        continue;
      }
    } else if (!field.options.tolerance) {
      if (previousFloat == inputValueFloat) {
        continue;
      }
    } else {
      let toleranceFloat = parseFloat(field.options.tolerance);
      if (field.options.tolerance_type == 'percentage') {
        if (
          inputValueFloat >= previousFloat * (1 - toleranceFloat / 100) &&
          inputValueFloat <= previousFloat * (1 + toleranceFloat / 100)
        ) {
          continue;
        }
      } else {
        if (
          inputValueFloat >= previousFloat - toleranceFloat &&
          inputValueFloat <= previousFloat + toleranceFloat
        ) {
          continue;
        }
      }
    }

    return false;
  }

  return true;
}

export function inputValuePasses(field, inputValue, inputValues) {
  if (!fieldHasTolerance(field)) {
    return false;
  }

  if (field.options.tolerance_basis == 'of_fixed') {
    return checkFixedTolerance(field, inputValue);
  }

  return checkLastN(field, inputValue, inputValues);
}
