interface BaseElement {
  type: string;
  value?: string;
  is_input?: boolean;
}

interface LocationTitleElement extends BaseElement {
  type: 'location_title';
}

interface CustomTextElement extends BaseElement {
  type: 'custom_text';
  value: string;
}

interface DateElement extends BaseElement {
  type: 'date';
  value: string;
}

interface StartDepthElement extends BaseElement {
  type: 'start_depth';
}

interface EndDepthElement extends BaseElement {
  type: 'end_depth';
}

export type UploadStructureElement =
  | LocationTitleElement
  | StartDepthElement
  | EndDepthElement
  | CustomTextElement
  | DateElement;

export type StructuresArray = UploadStructureElement[];

export const elementToText = (element: UploadStructureElement): string => {
  switch (element.type) {
    case 'location_title':
      return 'Location Title';
    case 'start_depth':
      return 'Start Depth';
    case 'end_depth':
      return 'End Depth';
    case 'date':
      return `Date (${element.value})`;
    case 'custom_text':
      return `Text (${element.value || ' '})`;
    default:
      return '';
  }
};

export const structureToPreview = (
  structure: StructuresArray,
  locationTitle = 'HA1',
  startDepth = '10m',
  endDepth = '15m',
  dateDDMM = '010324',
  dateYYMM = '240301'
) => {
  let preview = '';

  structure.forEach((element, elementIndex) => {
    // Add the given logic here
    if (element.type === 'location_title') {
      preview += locationTitle;
    } else if (element.type === 'date') {
      if (element.value === 'DDMMYY') {
        preview += dateDDMM;
      } else {
        preview += dateYYMM;
      }
    } else if (element.type === 'custom_text') {
      if (
        (endDepth === null || endDepth === '') &&
        structure[elementIndex + 1] &&
        structure[elementIndex + 1].type === 'end_depth'
      ) {
        return;
      }
      if (
        (startDepth === null || startDepth === '') &&
        structure[elementIndex + 1] &&
        structure[elementIndex + 1].type === 'start_depth'
      ) {
        return;
      }
      if (element.value === null) {
        preview += ' ';
      } else {
        preview += element.value;
      }
    } else if (element.type === 'start_depth') {
      if (startDepth !== null && startDepth !== '') {
        preview += startDepth;
      }
    } else if (element.type === 'end_depth') {
      if (endDepth !== null && endDepth !== '') {
        preview += endDepth;
      }
    }
  });

  return preview;
};

export const getDefaultStructures = () => {
  return [
    {
      id: 'default-structure-1',
      type: 'enviro',
      structure: [
        { type: 'location_title' },
        { type: 'custom_text', value: '@' },
        { type: 'start_depth' },
        { type: 'custom_text', value: '-' },
        { type: 'end_depth' },
      ],
      regex_string:
        '^([^@s]+)s*(@)?s*([0-9.]+)s*(?:[a-zA-Z]+)?s*(?:-s*([0-9.]+))?s*(?:s*([a-zA-Z]+))?s*(-)?s*(.*)$',
    },
    {
      id: 'default-structure-2',
      type: 'enviro',
      structure: [
        { type: 'location_title' },
        { type: 'custom_text', value: '_' },
        { type: 'start_depth' },
        { type: 'custom_text', value: '-' },
        { type: 'end_depth' },
      ],
      regex_string:
        '^([^_s]+)s*(_)?s*([0-9.]+)s*(?:[a-zA-Z]+)?s*(?:-s*([0-9.]+))?s*(?:s*([a-zA-Z]+))?s*(-)?s*(.*)$',
    },
    {
      id: 'default-structure-3',
      type: 'enviro',
      structure: [
        { type: 'location_title' },
        { type: 'custom_text', value: '@' },
        { type: 'start_depth' },
      ],
      regex_string: '(.+?)\\s*(@)\\s*(\\d+\\.?\\d*)\\s*[a-zA-Z]*',
    },
    {
      id: 'default-structure-4',
      type: 'enviro',
      structure: [
        { type: 'location_title' },
        { type: 'custom_text', value: '_' },
        { type: 'start_depth' },
      ],
      regex_string: '(.+?)\\s*(_)\\s*(\\d+\\.?\\d*)\\s*[a-zA-Z]*',
    },
  ];
};

export type ExtractedValue = UploadStructureElement & {
  extracted_value: string | null;
};

export const checkRegexPattern = (
  testString: string,
  format: StructuresArray
) => {
  const extractedValues = extractValues(testString, format);

  let extractedData: ExtractedValue[] = [];

  format.forEach((element, index) => {
    extractedData.push({
      ...element,
      extracted_value: extractedValues[index] || null,
    });
  });

  return extractedData;
};

export function buildRegexFromFormat(format: StructuresArray) {
  return format
    .map((f) => {
      if (f.type === 'date') {
        return '(\\d{6})'; // assuming DDMMYY is always 6 digits
      } else if (f.type === 'custom_text') {
        return `\\s*(${(f.value || ' ').replace(
          /[-\/\\^$*+?.()|[\]{}]/g,
          '\\$&'
        )})\\s*`;
      } else if (f.type === 'location_title') {
        return '(.+?)'; // match anything non-greedy
      } else if (f.type === 'start_depth' || f.type === 'end_depth') {
        return '(\\d+\\.?\\d*)\\s*[a-zA-Z]*'; // matches decimal numbers with optional spaces and units
      } else {
        return '(.+)'; // matches any character sequence
      }
    })
    .join('');
}

export function extractValues(originalTitle: string, format: StructuresArray) {
  const regexPattern = buildRegexFromFormat(format);

  const regex = new RegExp(`^${regexPattern}$`);
  const match = originalTitle.match(regex);
  if (match) {
    return match.slice(1).map((value, index) => {
      const type = format[index].type;
      if (type === 'start_depth' || type === 'end_depth') {
        return value.replace(/[a-zA-Z]*$/, ''); // strip trailing alphabetic characters e.g. units
      }
      return value;
    });
  } else {
    return [];
  }
}
