<template>
  <div
    class="alert text-break caption-container"
    :class="'alert-' + field.options.status || 'secondary'"
    v-html="content"
  ></div>
</template>

<script>
import * as bl from '../../business-logic';

export default {
  props: {
    field: Object,
  },
  computed: {
    content() {
      const {
        label,
        options: { content },
      } = this.field;

      return content
        ? bl.input_value.makeCaptionLinksTargetBlank(content)
        : label;
    },
  },
};
</script>

<style scoped>
img {
  max-width: 100% !important;
}

.alert > :last-child {
  margin-bottom: 0px !important;
}
</style>
