import moment from 'moment';
import timezone from 'moment-timezone';
import {
  DATE_FORMATS_BY_TYPE,
  DateFormat,
  DateType,
  checkHasTime,
} from '../business-model/date';
import type { GatherField, InputValue } from '../gather';

export const getTimezones = (country) => {
  return timezone.tz.zonesForCountry(country);
};

export const convertDate = (
  date: string,
  countryFormat: string = 'YYYY-MM-DD',
  withTime = false,
  timezone: string | null = null
) => {
  if (!date) {
    return;
  }

  // if the date is just zeros, like 0000-00-00, then return null
  if (date.match(/^0+$/)) {
    return null;
  }

  let formattedDate = moment.utc(date);

  // If date doesn't include time, don't convert to Timezone
  if (
    formattedDate.hour() !== 0 &&
    formattedDate.minute() !== 0 &&
    formattedDate.second() !== 0
  ) {
    if (timezone) {
      formattedDate = formattedDate.tz(timezone);
    } else {
      formattedDate = formattedDate.local();
    }
  }

  return formattedDate.format(countryFormat + (withTime ? ' hh:mm A' : ''));
};

export function getDateType(field: GatherField): DateType {
  return (field.options?.type ?? DateType.Datetime) as DateType;
}

export function formatDateInputValue(
  inputValue: InputValue,
  field: GatherField
): string {
  const { value: dateValue, value2: timeValue } = inputValue;
  const type = getDateType(field);

  if (type === DateType.Time || (type === DateType.Datetime && !dateValue)) {
    return timeValue ? (timeValue as string) : '';
  }

  let format =
    (field.options?.format === 'day'
      ? DateFormat.DayMonthYear
      : field.options?.format === 'month'
      ? DateFormat.MonthYear
      : field.options?.format === 'year'
      ? DateFormat.Year
      : field.options?.format) ?? DATE_FORMATS_BY_TYPE[type][0];
  const shouldIncludeTime = checkHasTime(type) && !!timeValue;
  if (shouldIncludeTime && !format.endsWith(DateFormat.Time)) {
    format = `${format} ${DateFormat.Time}`;
  }
  const value = shouldIncludeTime ? `${dateValue} ${timeValue}` : dateValue;
  return typeof value === 'string'
    ? moment(
        value,
        shouldIncludeTime
          ? `${DateFormat.YearMonthDay} ${DateFormat.Time}`
          : DateFormat.YearMonthDay
      ).format(format)
    : '';
}
