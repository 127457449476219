export enum DateType {
  Date = 'date',
  Datetime = 'datetime',
  Time = 'time',
}

export enum DateFormat {
  DayMonthYear = 'DD-MM-YYYY',
  MonthDayYear = 'MM-DD-YYYY',
  YearMonthDay = 'YYYY-MM-DD',
  MonthYear = 'MM-YYYY',
  Year = 'YYYY',
  Time = 'HH:mm',
}

export const DATE_FORMATS_BY_TYPE: Record<DateType, DateFormat[]> = {
  [DateType.Date]: [
    DateFormat.DayMonthYear,
    DateFormat.MonthDayYear,
    DateFormat.YearMonthDay,
    DateFormat.MonthYear,
    DateFormat.Year,
  ],
  [DateType.Datetime]: [
    DateFormat.DayMonthYear,
    DateFormat.MonthDayYear,
    DateFormat.YearMonthDay,
  ],
  [DateType.Time]: [DateFormat.Time],
};

export function checkHasTime(type: DateType) {
  return [DateType.Datetime, DateType.Time].includes(type);
}
