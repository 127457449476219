<script lang="ts" setup>
import { computed, watch } from 'vue';
import AddressLookup from '../../components/AddressLookup.vue';
import useLegacyRootBus from '../../composables/useLegacyRootBus';
import { GatherField, InputValue } from '../../gather';
import { Address } from '../../project';

const legacyRootBus = useLegacyRootBus();
const props = defineProps<{
  field: GatherField;
  inputValue: InputValue;
}>();

const addressData = computed(() => {
  return props.inputValue?.value2 as Address | undefined;
});

function locationSelected(data: Address) {
  legacyRootBus.$emit('updateInputValue', {
    inputValue: {
      ...props.inputValue,
      value: data.full_address,
      value2: data,
    },
    field: props.inputValue.template_field_id,
    sectionIndex: props.inputValue.template_section_index,
    templateTabId: props.inputValue.template_tab_id,
  });
}

function updateAddressData() {
  if (props.inputValue?.value2) {
    if (
      typeof props.inputValue.value2 === 'string' &&
      props.inputValue.value2.startsWith('{')
    ) {
      locationSelected(JSON.parse(props.inputValue.value2));
    }
  }
}

watch(
  () => props.inputValue?.value2,
  () => {
    updateAddressData();
  },
  { immediate: true }
);
</script>

<template>
  <div class="form-group">
    <label v-if="field.label" class="form-label">
      {{ field.label }}
      <sup v-if="field.is_required" class="text-danger">*</sup>
    </label>

    <AddressLookup
      :required="!!field.is_required"
      :addressData="addressData"
      :country="field.options?.restricted_country"
      :mustHaveCoordinates="false"
      @locationSelected="locationSelected"
    />
  </div>
</template>
