export const OPTION_TYPE_TEXT = 1;
export const OPTION_TYPE_NUMBER = 2;

export const OPTION_TYPE_OPTIONS = {
  [OPTION_TYPE_TEXT]: {
    text: 'Text',
  },
  [OPTION_TYPE_NUMBER]: {
    text: 'Number',
  },
};
