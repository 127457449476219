<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    size?: number | string;
    small?: boolean;
    large?: boolean;
    xl?: boolean;
    dataCy?: string;
  }>(),
  {
    size: '2rem',
  }
);

const actualSize = computed(() => {
  if (props.small) {
    return '1rem';
  }
  if (props.large) {
    return '2.4rem';
  }

  if (props.xl) {
    return '4.8rem';
  }

  return props.size;
});
</script>

<template>
  <svg
    :data-cy="dataCy"
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 1080"
    class="spinner-v2"
    :width="actualSize"
    :height="actualSize"
  >
    <path
      class="center"
      fill-rule="evenodd"
      style="fill: #33afaf"
      d="m488.6 482.8c0.4-0.1 0.7-0.5 1-0.8q10.5-12.3 25.2-18.9c6.6-3 13.6-5 20.8-5.8 3-0.4 6.1-0.8 9.2-0.7 12.3 0.1 23.9 3.1 34.7 9.1 13 7.3 23 17.7 29.6 31.1q6.3 12.6 7.3 26.7 1.8 27.6-14.9 49.6c-5.4 7-12 12.7-19.5 17.4-8.8 5.4-18.2 8.6-28.4 9.9-14.2 1.8-27.9-0.4-40.7-6.8-19.5-9.7-32.2-25.2-38.2-46.1-2.1-7.3-2.8-14.8-2.4-22.5 0.6-11 3.6-21.3 8.8-31 2-3.6 4.3-6.9 6.8-10.1 0.2-0.4 0.6-0.7 0.7-1.2zm48.7 53.2q0.3 0.8 1.2 1.2c0.2 0.4 0.6 0.5 0.9 0.7 2.9 1.7 5.8 1.2 8.7 0.2 1.8-0.7 3.5-1.5 4.5-3.2 0.3-0.3 0.5-0.5 0.7-0.7 2.2-3.6 1.4-9.4-1.8-12.2-3.3-3.7-10.7-4.9-15.2 1-2 2.3-2.4 5.1-1.9 8 0.3 1.9 1.1 3.7 2.9 5z"
    />
    <path
      class="inner inner-2"
      style="opacity: 0.8; fill: #27aae1"
      d="m338.2 736c-89.4-89.1-106.8-227.6-42.1-336.1 15-25 47.4-33.2 72.5-18.2 25.1 15 33.2 47.5 18.2 72.6-48.2 80.2-22.3 184.2 57.9 232.4 11.2 6.8 23.1 12.2 35.5 16.2 28.5 6.4 46.3 34.7 39.9 63.2-6.5 28.5-34.8 46.3-63.3 39.9q-4.4-1-8.5-2.7c-41.6-13.4-79.3-36.4-110.1-67.3z"
    />
    <path
      class="inner inner-1"
      style="opacity: 0.8; fill: #27aae1"
      d="m753.1 668.9c-6.5 0-13-1.2-19.1-3.6-27.2-10.4-40.8-41-30.3-68.2q0-0.1 0-0.1c33.8-87.3-9.6-185.4-96.9-219.1-13.2-5.1-27-8.6-41.1-10.2-29.1-3.5-49.8-29.9-46.4-58.9 3.5-29.1 29.8-49.9 58.9-46.4 150.9 17.9 258.7 154.7 240.9 305.6-2.8 23-8.4 45.5-16.7 67.1-7.9 20.4-27.5 33.8-49.3 33.8z"
    />
    <path
      class="outer outer-2"
      style="opacity: 0.8; fill: #32639e"
      d="m749.2 991.5c-32.3 0.1-58.5-26-58.6-58.3-0.1-22 12.2-42.2 31.8-52.2 174.1-90.1 254.3-295.5 187.2-479.7l-4.8-12.4q-1.4-3.5-2.3-7.2c-61.1-143-201.8-235.6-357.3-235.2-62.1-0.2-123.3 14.7-178.4 43.3-28.7 14.9-64 3.6-78.9-25.1-14.8-28.7-3.5-64 25.2-78.9 248.5-128 553.8-30.2 681.7 218.3 12.7 24.7 23.4 50.4 31.9 76.8q1.5 4.7 2.2 9.6c69.6 233.2-36.7 482.9-253 594.5-8.3 4.3-17.4 6.5-26.7 6.5z"
    />
    <path
      class="outer outer-1"
      style="opacity: 0.8; fill: #32639e"
      d="m539.6 1045.8c-279.3-0.1-505.8-226.6-505.7-506 0-100.9 30.3-199.6 86.8-283.2 18.1-26.8 54.5-33.8 81.3-15.6 26.7 18.1 33.7 54.5 15.6 81.2-120.3 178-73.5 419.7 104.5 539.9 64.2 43.4 140 66.7 217.5 66.7 32.3 0 58.5 26.2 58.5 58.5 0 32.3-26.2 58.5-58.5 58.5z"
    />
  </svg>
</template>
