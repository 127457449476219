export default {
  LINZ: 'basemaps.linz.govt.nz',
  MAPBOX: 'api.mapbox.com',
  METROMAP: 'api-v3.metromap.com.au',
  NEARMAP: 'api.nearmap.com',
  OPENSTREETMAP: 'tile.openstreetmap.org',
  NSW_AERIAL: 'maps3.six.nsw.gov.au',
  QLD_AERIAL: 'spatial-img.information.qld.gov.au',
  SA_AERIAL: 'imagemap.geohub.sa.gov.au',
  VICMAP_BASEMAPS_AERIAL: 'base.maps.vic.gov.au',
  VICMAP_BASEMAPS_CARTOGRAPHIC: 'base.maps.vic.gov.au',
  VICMAP_BASEMAPS_OVERLAY: 'base.maps.vic.gov.au',
  WA_AERIAL: 'token.slip.wa.gov.au',
  CA_TOPOGRAPHIC: 'tiles.arcgis.com',
  GB_BRISTOL_AND_SURROUNDING_AREA: 'maps2.bristol.gov.uk',
  GB_OS_MAPS_ROAD: 'api.os.uk',
  GB_OS_VECTOR: 'api.os.uk',
};
