<template>
  <div>
    <label id="file">
      {{ field.label }}<sup v-if="isRequired" class="text-danger">*</sup>
      <br />
      <small class="text-muted">(wav,mpeg,mp4,aac,aacp,ogg,webm,flac)</small>
    </label>

    <div class="mt-2">
      <template v-if="!src">
        <div v-if="!mediaRecorder" class="d-flex">
          <ButtonSelectFile
            @triggerUpload="recordAudio"
            :hasError="isRequired && !value.value"
            class="me-2"
            icon="fa-microphone-alt"
          >
            Record Audio
          </ButtonSelectFile>
          <ButtonSelectFile
            @triggerUpload="fileAudio"
            :hasError="isRequired && !value.value"
            :isUploading="loading"
          >
            Upload Audio
          </ButtonSelectFile>
          <input
            type="file"
            ref="audioFile"
            class="form-control-file d-none"
            @change="fileChange"
            :accept="supportedTypes"
          />
        </div>
        <button
          v-else
          class="btn btn-danger w-100"
          @click.prevent="stopRecording"
        >
          Stop Recording
        </button>
      </template>

      <div v-if="value.value2" class="mb-2">
        <span>{{ value.value2 }}</span>
        <small v-if="fileCheck" class="d-block">{{ fileSize }}</small>
      </div>

      <div v-show="srcCheck && canPlay" :key="src">
        <audio
          controls
          :src="src"
          :type="mimeType"
          ref="audioPlayer"
          class="w-100"
          style="height: 40px"
        >
          Your device does not support the
          <code>audio</code> element.
        </audio>
      </div>
      <div v-if="srcCheck && !canPlay" class="alert alert-warning">
        Device does not support playing <code>{{ mimeType }}</code> file types.
        <a :href="src" download class="text-warning">
          <i class="fas fa-file-download"></i> Download here
        </a>
      </div>

      <button
        v-if="src"
        type="button"
        class="btn btn-outline-danger w-100 mt-2"
        @click.prevent="clearFile"
      >
        Remove File
      </button>
    </div>
  </div>
</template>

<script>
import ButtonSelectFile from '../../components/ButtonSelectFile.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    inputValues: {
      type: Array,
      required: true,
    },
    field: {
      required: true,
    },
  },
  data: () => ({
    isGather: false,
    loading: false,
    audioPlayer: null,
    canPlay: false,
    mimeType: null,
    supportedTypes:
      'audio/wav,audio/mpeg,audio/mp4,audio/aac,audio/aacp,audio/ogg,audio/webm,audio/flac',
    stream: null,
    tracks: null,
    mediaRecorder: null,
    recordedChunks: [],
    isSafari: false,
  }),
  components: { ButtonSelectFile },
  mounted() {
    this.isSafari =
      navigator.userAgent.indexOf('Safari') > -1 &&
      navigator.userAgent.indexOf('Chrome') <= 0;
    this.audioPlayer = this.$refs.audioPlayer;
    this.isGather = import.meta.env.VITE_APP_URL.toLowerCase().includes(
      'gather'
    );
    this.setAudioContent(this.value.value);
  },
  computed: {
    isRequired() {
      return this.field?.is_required || false;
    },
    src() {
      if (this.value?.value instanceof Blob)
        return URL.createObjectURL(this.value.value);
      if (typeof this.value?.value == 'string')
        return `/api/images/value/${this.value.project_id}/${this.value.value}`;
      return false;
    },
    srcCheck() {
      return (
        this.src &&
        (this.value?.value instanceof Blob ||
          typeof this.value?.value == 'string')
      );
    },
    fileCheck() {
      return this.value?.value instanceof Blob;
    },
    fileSize() {
      let number = this.value?.value?.size;
      if (number < 1024) {
        return number + ' bytes';
      } else if (number >= 1024 && number < 1048576) {
        return (number / 1024).toFixed(1) + ' KB';
      } else if (number >= 1048576) {
        return (number / 1048576).toFixed(1) + ' MB';
      }
    },
  },
  watch: {
    'value.value'(updated) {
      this.setAudioContent(updated);
    },
  },
  methods: {
    requestAudio() {
      return navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((stream) => {
          this.tracks = stream.getAudioTracks();
          this.stream = stream;
        })
        .catch((err) => {
          this.stopRecording();
          throw err;
        });
    },
    recordAudio(e) {
      this.requestAudio(e).then(() => {
        const options = {
          mimeType: this.isSafari ? 'audio/mp4' : 'audio/webm',
        };
        this.recordedChunks = [];
        this.mediaRecorder = new MediaRecorder(this.stream, options);
        this.mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            this.recordedChunks.push(e.data);
          }
          const blob = new Blob([...this.recordedChunks], {
            type: e.data.type,
          });
          this.$root.$emit('updateInputValue', {
            inputValue: {
              ...this.value,
              value: blob,
              value2: `audio-${this.inputValues?.length || 1}.${
                this.isSafari ? 'mp4' : 'webm'
              }`,
            },
            field: this.value.template_field_id,
            sectionIndex: this.value.template_section_index,
            templateTabId: this.value.template_tab_id,
          });
        };
        this.mediaRecorder.start();
      });
    },
    stopRecording() {
      this.mediaRecorder?.stop();
      this.$nextTick(() => {
        this.mediaRecorder = null;
      });
    },
    fileChange(e) {
      const file = e.target.files[0];
      if (
        this.supportedTypes.includes(file.type) < 0 ||
        this.supportedTypes.includes(file.type) === false
      ) {
        this.$toastStore.error('File type is not supported for audio upload');
        return;
      }
      if (file.size / 1048576 > 99) {
        this.$toastStore.error('File size must be under 100MB');
        return;
      }
      this.$root.$emit('updateInputValue', {
        inputValue: { ...this.value, value: file, value2: file.name },
        field: this.value.template_field_id,
        sectionIndex: this.value.template_section_index,
        templateTabId: this.value.template_tab_id,
      });
      e.target.value = '';
    },
    clearFile() {
      this.$root.$emit('updateInputValue', {
        inputValue: { ...this.value, value: null, value2: null },
        field: this.value.template_field_id,
        sectionIndex: this.value.template_section_index,
        templateTabId: this.value.template_tab_id,
      });
    },
    setAudioContent(updated) {
      if (updated && updated instanceof Blob) {
        this.mimeType = updated.type;
        this.canPlay = this.audioPlayer.canPlayType(this.mimeType);
      }
      if (updated && typeof updated == 'string') {
        this.mimeType = `audio/${this.value.value2
          .split('.')
          .at(-1)
          .toLowerCase()}`;
        this.canPlay = this.audioPlayer.canPlayType(this.mimeType);
      }
      if (!updated) {
        this.canPlay = false;
      }
    },
    fileAudio() {
      this.$refs.audioFile.click();
    },
  },
  beforeDestroy() {
    this.stopRecording();
  },
};
</script>

<style scoped>
.remove-file {
  max-width: 10em;
}
</style>
